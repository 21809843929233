import * as Yup from "yup";

export const initialValues = {
    subjectPropertyAddress: '',
    subjectPropertyPosition: '',
    amountLookingFor: '',
    doYouHaveAnAppraisal: '',
    appraisedValue: '',
    annualPropertyText: '',
    monthlyMaintFee: '',
    arrears: '',
    occupancy: '',
    style: '',
    mortgageHolderFirst: '',
    mortgageHolderSecond: '',
    mortgageHolderThird: '',
    mortgageBalanceFirst: '',
    mortgageBalanceSecond: '',
    mortgageBalanceThird: '',
    mortgagePaymentFirst: '',
    mortgagePaymentSecond: '',
    mortgagePaymentThird: '',
    interestRateFirst: '',
    interestRateSecond: '',
    interestRateThird: '',
    maturityDateFirst: '',
    maturityDateSecond: '',
    maturityDateThird: '',
    arrearsFirst: '',
    arrearsSecond: '',
    arrearsThird: '',
    acceptance: ''    
}

export const validationSchema = Yup.object({
    subjectPropertyAddress: Yup.string().required("This is a required field"),
    subjectPropertyPosition: Yup.string().required("This is a required field"),
    acceptance:  Yup.bool().oneOf([true], 'You need to accept the terms').required('You need to accept the terms'),
  });