import { useFormik } from "formik";
import { Input, Button, CurrencyInputTag } from "../../../components";
import {
  initialValues,
  validationSchema,
  secondaryInitialValues,
  secondValidationSchema,
} from "../../../utils/stepTwoValidation";
import {
  formatInputDate,
} from "../../../utils/helpers";
import { ToastContainer, toast } from "react-toastify";

const StepTwo = (props) => {
  const formik = useFormik({
    initialValues:
      props.numberOfApplicants === 2 ? secondaryInitialValues : initialValues,
    validationSchema:
      props.numberOfApplicants === 2
        ? secondValidationSchema
        : validationSchema,
    onSubmit: function (values) {
      props.stepTwoFormValues({ ...values });
      props.next();
    },
  });

  const formatDateHandler = (e, fieldValue) => {
    const dateValue = formatInputDate(e.target.value);
    console.log(dateValue);
    formik.setFieldValue(fieldValue, dateValue);
  };

  const errorHandler = () =>
    toast.error("Please fill all the required fields", {
      position: "bottom-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  return (
    <form onSubmit={formik.handleSubmit}>
      <h2 className="formtitle">Applicant</h2>
      <div className="inputRow">
        <div>
          <Input
            type="text"
            label="Name(First, Last)"
            name="firstApplicantName"
            border={
              formik.touched.firstApplicantName &&
              formik.errors.firstApplicantName &&
              "2px solid var(--redColor)"
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstApplicantName}
            required
          />
          {formik.touched.firstApplicantName &&
            formik.errors.firstApplicantName && (
              <p className="errorText">{formik.errors.firstApplicantName}</p>
            )}
        </div>
        <div>
          <Input
            type="text"
            label="SIN"
            name="firstApplicantSin"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstApplicantSin}
          />
        </div>
        <div>
          <Input
            label="Date of Birth(DD/MM/YYYY)"
            type="text"
            name="firstApplicantDob"
            border={
              formik.touched.firstApplicantDob &&
              formik.errors.firstApplicantDob &&
              "2px solid var(--redColor)"
            }
            onChange={(e) => {
              formatDateHandler(e, "firstApplicantDob");
            }}
            onBlur={formik.handleBlur}
            value={formik.values.firstApplicantDob}
            required
          />
          {formik.touched.firstApplicantDob &&
            formik.errors.firstApplicantDob && (
              <p className="errorText">{formik.errors.firstApplicantDob}</p>
            )}
        </div>
      </div>
      <div className="inputRow">
        <div>
          <Input
            type="text"
            label="Current Address(complete)"
            name="firstApplicantCurrentAddress"
            border={
              formik.touched.firstApplicantCurrentAddress &&
              formik.errors.firstApplicantCurrentAddress &&
              "2px solid var(--redColor)"
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstApplicantCurrentAddress}
            required
          />
          {formik.touched.firstApplicantCurrentAddress &&
            formik.errors.firstApplicantCurrentAddress && (
              <p className="errorText">
                {formik.errors.firstApplicantCurrentAddress}
              </p>
            )}
        </div>
        <div>
          <Input
            type="text"
            label="Duration at Address"
            border={
              formik.touched.firstApplicantDurationAtAddress &&
              formik.errors.firstApplicantDurationAtAddress &&
              "2px solid var(--redColor)"
            }
            name="firstApplicantDurationAtAddress"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstApplicantDurationAtAddress}
            required
          />
          {formik.touched.firstApplicantDurationAtAddress &&
            formik.errors.firstApplicantDurationAtAddress && (
              <p className="errorText">
                {formik.errors.firstApplicantDurationAtAddress}
              </p>
            )}
        </div>
      </div>
      <div className="inputRow">
        <div>
          <Input
            type="text"
            label="Previous Address(if less than 3 years)"
            name="firstApplicantPreviousAddress"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstApplicantPreviousAddress}
          />
        </div>
      </div>
      <div className="inputRow">
        <div>
          <Input
            type="text"
            label="Marital Status"
            name="firstApplicantMaritalStatus"
            border={
              formik.touched.firstApplicantMaritalStatus &&
              formik.errors.firstApplicantMaritalStatus &&
              "2px solid var(--redColor)"
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstApplicantMaritalStatus}
            required
          />
          {formik.touched.firstApplicantMaritalStatus &&
            formik.errors.firstApplicantMaritalStatus && (
              <p className="errorText">
                {formik.errors.firstApplicantMaritalStatus}
              </p>
            )}
        </div>
        <div>
          <Input
            type="text"
            label="Dependents"
            name="firstApplicantDependents"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstApplicantDependents}
          />
        </div>
      </div>
      <div className="inputRow">
        <div>
          <Input
            type="number"
            label="Home Phone"
            name="firstApplicantHomePhone"
            border={
              formik.touched.firstApplicantHomePhone &&
              formik.errors.firstApplicantHomePhone &&
              "2px solid var(--redColor)"
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstApplicantHomePhone}
            required
          />
          {formik.touched.firstApplicantHomePhone &&
            formik.errors.firstApplicantHomePhone && (
              <p className="errorText">
                {formik.errors.firstApplicantHomePhone}
              </p>
            )}
        </div>
        <div>
          <Input
            type="number"
            label="Office Phone"
            border={
              formik.touched.firstApplicantOfficePhone &&
              formik.errors.firstApplicantOfficePhone &&
              "2px solid var(--redColor)"
            }
            name="firstApplicantOfficePhone"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstApplicantOfficePhone}
          />
          {formik.touched.firstApplicantOfficePhone &&
            formik.errors.firstApplicantOfficePhone && (
              <p className="errorText">
                {formik.errors.firstApplicantOfficePhone}
              </p>
            )}
        </div>
        <div>
          <Input
            label="Cell Phone"
            type="number"
            name="firstApplicantCellPhone"
            border={
              formik.touched.firstApplicantCellPhone &&
              formik.errors.firstApplicantCellPhone &&
              "2px solid var(--redColor)"
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstApplicantCellPhone}
            required
          />
          {formik.touched.firstApplicantCellPhone &&
            formik.errors.firstApplicantCellPhone && (
              <p className="errorText">
                {formik.errors.firstApplicantCellPhone}
              </p>
            )}
        </div>
      </div>
      <div className="inputRow">
        <div>
          <Input
            type="email"
            label="Primary Email"
            name="firstApplicantPrimaryEmail"
            border={
              formik.touched.firstApplicantPrimaryEmail &&
              formik.errors.firstApplicantPrimaryEmail &&
              "2px solid var(--redColor)"
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstApplicantPrimaryEmail}
            required
          />
          {formik.touched.firstApplicantPrimaryEmail &&
            formik.errors.firstApplicantPrimaryEmail && (
              <p className="errorText">
                {formik.errors.firstApplicantPrimaryEmail}
              </p>
            )}
        </div>
        <div>
          <Input
            type="email"
            label="Secondary Email"
            border={
              formik.touched.firstApplicantSecondaryEmail &&
              formik.errors.firstApplicantSecondaryEmail &&
              "2px solid var(--redColor)"
            }
            name="firstApplicantSecondaryEmail"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstApplicantSecondaryEmail}
          />
          {formik.touched.firstApplicantSecondaryEmail &&
            formik.errors.firstApplicantSecondaryEmail && (
              <p className="errorText">
                {formik.errors.firstApplicantSecondaryEmail}
              </p>
            )}
        </div>
      </div>
      <h2 className="formtitle">Applicant Employment Information</h2>
      <div className="inputRow">
        <div>
          <Input
            type="text"
            label="Current Employer"
            name="firstApplicantCurrentEmployer"
            border={
              formik.touched.firstApplicantCurrentEmployer &&
              formik.errors.firstApplicantCurrentEmployer &&
              "2px solid var(--redColor)"
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstApplicantCurrentEmployer}
            required
          />
          {formik.touched.firstApplicantCurrentEmployer &&
            formik.errors.firstApplicantCurrentEmployer && (
              <p className="errorText">
                {formik.errors.firstApplicantCurrentEmployer}
              </p>
            )}
        </div>
        <div>
          <Input
            type="text"
            label="Duration Employed"
            border={
              formik.touched.firstApplicantDurationEmployed &&
              formik.errors.firstApplicantDurationEmployed &&
              "2px solid var(--redColor)"
            }
            name="firstApplicantDurationEmployed"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstApplicantDurationEmployed}
            required
          />
          {formik.touched.firstApplicantDurationEmployed &&
            formik.errors.firstApplicantDurationEmployed && (
              <p className="errorText">
                {formik.errors.firstApplicantDurationEmployed}
              </p>
            )}
        </div>
      </div>
      <div className="inputRow">
        <div>
          <Input
            type="text"
            label="Address(complete)"
            name="firstApplicantEmploymentAddress"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstApplicantEmploymentAddress}
          />
        </div>
      </div>
      <div className="inputRow">
        <div>
          <Input
            type="number"
            label="Phone"
            name="firstApplicantEmploymentPhone"
            border={
              formik.touched.firstApplicantEmploymentPhone &&
              formik.errors.firstApplicantEmploymentPhone &&
              "2px solid var(--redColor)"
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstApplicantEmploymentPhone}
            required
          />
          {formik.touched.firstApplicantEmploymentPhone &&
            formik.errors.firstApplicantEmploymentPhone && (
              <p className="errorText">
                {formik.errors.firstApplicantEmploymentPhone}
              </p>
            )}
        </div>
        <div>
          <Input
            type="email"
            label="Email"
            border={
              formik.touched.firstApplicantEmploymentEmail &&
              formik.errors.firstApplicantEmploymentEmail &&
              "2px solid var(--redColor)"
            }
            name="firstApplicantEmploymentEmail"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstApplicantEmploymentEmail}
            required
          />
          {formik.touched.firstApplicantEmploymentEmail &&
            formik.errors.firstApplicantEmploymentEmail && (
              <p className="errorText">
                {formik.errors.firstApplicantEmploymentEmail}
              </p>
            )}
        </div>
      </div>
      <div className="inputRow">
        <div>
          <Input
            type="text"
            label="Position"
            name="firstApplicantPosition"
            border={
              formik.touched.firstApplicantPosition &&
              formik.errors.firstApplicantPosition &&
              "2px solid var(--redColor)"
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstApplicantPosition}
            required
          />
          {formik.touched.firstApplicantPosition &&
            formik.errors.firstApplicantPosition && (
              <p className="errorText">
                {formik.errors.firstApplicantPosition}
              </p>
            )}
        </div>
        <div>
          <Input
            type="text"
            label="Income Type"
            name="firstApplicantIncomeType"
            border={
              formik.touched.firstApplicantIncomeType &&
              formik.errors.firstApplicantIncomeType &&
              "2px solid var(--redColor)"
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstApplicantIncomeType}
            required
          />
          {formik.touched.firstApplicantIncomeType &&
            formik.errors.firstApplicantIncomeType && (
              <p className="errorText">
                {formik.errors.firstApplicantIncomeType}
              </p>
            )}
        </div>
        <div>
          <CurrencyInputTag
            name="firstApplicantGrossAnnualIncome"
            label="Gross Annual Income"
            onChange={(e) => {
              const value = e.target.value;
              const parsedValue = value.replace(/[^\d.]/gi, "");
              const currencyInt = parseFloat(parsedValue);
              const currencyValue = currencyInt.toLocaleString();
              formik.setFieldValue('firstApplicantGrossAnnualIncome', currencyValue)
            }}
            onBlur={formik.handleBlur}
          />
        </div>
      </div>
      <div className="inputRow">
        <div>
          <Input
            type="text"
            label="Additional Job"
            name="firstApplicantAdditionalJob"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstApplicantAdditionalJob}
          />
        </div>
        <div>
          <Input
            type="text"
            label="Income Type"
            name="firstApplicantAdditionalIncomeType"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstApplicantAdditionalIncomeType}
          />
        </div>
        <div>
          <CurrencyInputTag
            name="firstApplicantAdditionalGrossIncome"
            label="Gross Income"
            onChange={(e) => {
              const value = e.target.value;
              const parsedValue = value.replace(/[^\d.]/gi, "");
              const currencyInt = parseFloat(parsedValue);
              const currencyValue = currencyInt.toLocaleString();
              formik.setFieldValue('firstApplicantAdditionalGrossIncome', currencyValue)
            }}
            onBlur={formik.handleBlur}
          />
        </div>
      </div>
      <div className="inputRow">
        <div>
          <Input
            type="text"
            label="Previous Employer(complete only if current is less than 3 years)"
            name="firstApplicantPreviousEmployer"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstApplicantPreviousEmployer}
          />
        </div>
        <div>
          <Input
            type="text"
            label="Duration Employed"
            name="firstApplicantPreviousDurationEmployed"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstApplicantPreviousDurationEmployed}
          />
        </div>
      </div>
      <div className="inputRow">
        <div>
          <Input
            type="number"
            label="Phone"
            name="firstApplicantPreviousPhone"
            border={
              formik.touched.firstApplicantPreviousPhone &&
              formik.errors.firstApplicantPreviousPhone &&
              "2px solid var(--redColor)"
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstApplicantPreviousPhone}
          />
          {formik.touched.firstApplicantPreviousPhone &&
            formik.errors.firstApplicantPreviousPhone && (
              <p className="errorText">
                {formik.errors.firstApplicantPreviousPhone}
              </p>
            )}
        </div>
        <div>
          <Input
            type="email"
            label="Email"
            border={
              formik.touched.firstApplicantPreviousEmail &&
              formik.errors.firstApplicantPreviousEmail &&
              "2px solid var(--redColor)"
            }
            name="firstApplicantPreviousEmail"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstApplicantPreviousEmail}
          />
          {formik.touched.firstApplicantPreviousEmail &&
            formik.errors.firstApplicantPreviousEmail && (
              <p className="errorText">
                {formik.errors.firstApplicantPreviousEmail}
              </p>
            )}
        </div>
      </div>
      <div className="inputRow">
        <div>
          <Input
            type="text"
            label="Position"
            name="firstApplicantPreviousPosition"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstApplicantPreviousPosition}
          />
        </div>
        <div>
          <Input
            type="text"
            label="Income Type"
            name="firstApplicantPreviousIncomeType"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstApplicantPreviousIncomeType}
          />
        </div>
        <div>
          <CurrencyInputTag
            name="firstApplicantPreviousGrossAnnualIncome"
            label="Gross Annual Income"
            onChange={(e) => {
              const value = e.target.value;
              const parsedValue = value.replace(/[^\d.]/gi, "");
              const currencyInt = parseFloat(parsedValue);
              const currencyValue = currencyInt.toLocaleString();
              formik.setFieldValue('firstApplicantPreviousGrossAnnualIncome', currencyValue)
            }}
            onBlur={formik.handleBlur}
          />
        </div>
      </div>
      {props.numberOfApplicants === 2 ? (
        <>
          <h2 className="formtitle">Second Applicant</h2>
          <div className="inputRow">
            <div>
              <Input
                type="text"
                label="Name(second, Last)"
                name="secondApplicantName"
                border={
                  formik.touched.secondApplicantName &&
                  formik.errors.secondApplicantName &&
                  "2px solid var(--redColor)"
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.secondApplicantName}
                required
              />
              {formik.touched.secondApplicantName &&
                formik.errors.secondApplicantName && (
                  <p className="errorText">
                    {formik.errors.secondApplicantName}
                  </p>
                )}
            </div>
            <div>
              <Input
                type="text"
                label="SIN"
                name="secondApplicantSin"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.secondApplicantSin}
              />
            </div>
            <div>
              <Input
                label="Date of Birth(DD/MM/YYYY)"
                type="text"
                name="secondApplicantDob"
                border={
                  formik.touched.secondApplicantDob &&
                  formik.errors.secondApplicantDob &&
                  "2px solid var(--redColor)"
                }
                onChange={(e) => {
                  formatDateHandler(e, "secondApplicantDob");
                }}
                onBlur={formik.handleBlur}
                value={formik.values.secondApplicantDob}
                required
              />
              {formik.touched.secondApplicantDob &&
                formik.errors.secondApplicantDob && (
                  <p className="errorText">
                    {formik.errors.secondApplicantDob}
                  </p>
                )}
            </div>
          </div>
          <div className="inputRow">
            <div>
              <Input
                type="text"
                label="Current Address(complete)"
                name="secondApplicantCurrentAddress"
                border={
                  formik.touched.secondApplicantCurrentAddress &&
                  formik.errors.secondApplicantCurrentAddress &&
                  "2px solid var(--redColor)"
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.secondApplicantCurrentAddress}
                required
              />
              {formik.touched.secondApplicantCurrentAddress &&
                formik.errors.secondApplicantCurrentAddress && (
                  <p className="errorText">
                    {formik.errors.secondApplicantCurrentAddress}
                  </p>
                )}
            </div>
            <div>
              <Input
                type="text"
                label="Duration at Address"
                border={
                  formik.touched.secondApplicantDurationAtAddress &&
                  formik.errors.secondApplicantDurationAtAddress &&
                  "2px solid var(--redColor)"
                }
                name="secondApplicantDurationAtAddress"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.secondApplicantDurationAtAddress}
                required
              />
              {formik.touched.secondApplicantDurationAtAddress &&
                formik.errors.secondApplicantDurationAtAddress && (
                  <p className="errorText">
                    {formik.errors.secondApplicantDurationAtAddress}
                  </p>
                )}
            </div>
          </div>
          <div className="inputRow">
            <div>
              <Input
                type="text"
                label="Previous Address(if less than 3 years)"
                name="secondApplicantPreviousAddress"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.secondApplicantPreviousAddress}
              />
            </div>
          </div>
          <div className="inputRow">
            <div>
              <Input
                type="text"
                label="Marital Status"
                name="secondApplicantMaritalStatus"
                border={
                  formik.touched.secondApplicantMaritalStatus &&
                  formik.errors.secondApplicantMaritalStatus &&
                  "2px solid var(--redColor)"
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.secondApplicantMaritalStatus}
                required
              />
              {formik.touched.secondApplicantMaritalStatus &&
                formik.errors.secondApplicantMaritalStatus && (
                  <p className="errorText">
                    {formik.errors.secondApplicantMaritalStatus}
                  </p>
                )}
            </div>
            <div>
              <Input
                type="text"
                label="Dependents"
                name="secondApplicantDependents"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.secondApplicantDependents}
              />
            </div>
          </div>
          <div className="inputRow">
            <div>
              <Input
                type="number"
                label="Home Phone"
                name="secondApplicantHomePhone"
                border={
                  formik.touched.secondApplicantHomePhone &&
                  formik.errors.secondApplicantHomePhone &&
                  "2px solid var(--redColor)"
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.secondApplicantHomePhone}
                required
              />
              {formik.touched.secondApplicantHomePhone &&
                formik.errors.secondApplicantHomePhone && (
                  <p className="errorText">
                    {formik.errors.secondApplicantHomePhone}
                  </p>
                )}
            </div>
            <div>
              <Input
                type="number"
                label="Office Phone"
                border={
                  formik.touched.secondApplicantOfficePhone &&
                  formik.errors.secondApplicantOfficePhone &&
                  "2px solid var(--redColor)"
                }
                name="secondApplicantOfficePhone"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.secondApplicantOfficePhone}
              />
              {formik.touched.secondApplicantOfficePhone &&
                formik.errors.secondApplicantOfficePhone && (
                  <p className="errorText">
                    {formik.errors.secondApplicantOfficePhone}
                  </p>
                )}
            </div>
            <div>
              <Input
                label="Cell Phone"
                type="number"
                name="secondApplicantCellPhone"
                border={
                  formik.touched.secondApplicantCellPhone &&
                  formik.errors.secondApplicantCellPhone &&
                  "2px solid var(--redColor)"
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.secondApplicantCellPhone}
                required
              />
              {formik.touched.secondApplicantCellPhone &&
                formik.errors.secondApplicantCellPhone && (
                  <p className="errorText">
                    {formik.errors.secondApplicantCellPhone}
                  </p>
                )}
            </div>
          </div>
          <div className="inputRow">
            <div>
              <Input
                type="email"
                label="Primary Email"
                name="secondApplicantPrimaryEmail"
                border={
                  formik.touched.secondApplicantPrimaryEmail &&
                  formik.errors.secondApplicantPrimaryEmail &&
                  "2px solid var(--redColor)"
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.secondApplicantPrimaryEmail}
                required
              />
              {formik.touched.secondApplicantPrimaryEmail &&
                formik.errors.secondApplicantPrimaryEmail && (
                  <p className="errorText">
                    {formik.errors.secondApplicantPrimaryEmail}
                  </p>
                )}
            </div>
            <div>
              <Input
                type="email"
                label="Secondary Email"
                border={
                  formik.touched.secondApplicantSecondaryEmail &&
                  formik.errors.secondApplicantSecondaryEmail &&
                  "2px solid var(--redColor)"
                }
                name="secondApplicantSecondaryEmail"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.secondApplicantSecondaryEmail}
              />
              {formik.touched.secondApplicantSecondaryEmail &&
                formik.errors.secondApplicantSecondaryEmail && (
                  <p className="errorText">
                    {formik.errors.secondApplicantSecondaryEmail}
                  </p>
                )}
            </div>
          </div>
          <h2 className="formtitle">Second Applicant Employment Information</h2>
          <div className="inputRow">
            <div>
              <Input
                type="text"
                label="Current Employer"
                name="secondApplicantCurrentEmployer"
                border={
                  formik.touched.secondApplicantCurrentEmployer &&
                  formik.errors.secondApplicantCurrentEmployer &&
                  "2px solid var(--redColor)"
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.secondApplicantCurrentEmployer}
                required
              />
              {formik.touched.secondApplicantCurrentEmployer &&
                formik.errors.secondApplicantCurrentEmployer && (
                  <p className="errorText">
                    {formik.errors.secondApplicantCurrentEmployer}
                  </p>
                )}
            </div>
            <div>
              <Input
                type="text"
                label="Duration Employed"
                border={
                  formik.touched.secondApplicantDurationEmployed &&
                  formik.errors.secondApplicantDurationEmployed &&
                  "2px solid var(--redColor)"
                }
                name="secondApplicantDurationEmployed"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.secondApplicantDurationEmployed}
                required
              />
              {formik.touched.secondApplicantDurationEmployed &&
                formik.errors.secondApplicantDurationEmployed && (
                  <p className="errorText">
                    {formik.errors.secondApplicantDurationEmployed}
                  </p>
                )}
            </div>
          </div>
          <div className="inputRow">
            <div>
              <Input
                type="text"
                label="Address(complete)"
                name="secondApplicantEmploymentAddress"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.secondApplicantEmploymentAddress}
              />
            </div>
          </div>
          <div className="inputRow">
            <div>
              <Input
                type="number"
                label="Phone"
                name="secondApplicantEmploymentPhone"
                border={
                  formik.touched.secondApplicantEmploymentPhone &&
                  formik.errors.secondApplicantEmploymentPhone &&
                  "2px solid var(--redColor)"
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.secondApplicantEmploymentPhone}
                required
              />
              {formik.touched.secondApplicantEmploymentPhone &&
                formik.errors.secondApplicantEmploymentPhone && (
                  <p className="errorText">
                    {formik.errors.secondApplicantEmploymentPhone}
                  </p>
                )}
            </div>
            <div>
              <Input
                type="email"
                label="Email"
                border={
                  formik.touched.secondApplicantEmploymentEmail &&
                  formik.errors.secondApplicantEmploymentEmail &&
                  "2px solid var(--redColor)"
                }
                name="secondApplicantEmploymentEmail"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.secondApplicantEmploymentEmail}
                required
              />
              {formik.touched.secondApplicantEmploymentEmail &&
                formik.errors.secondApplicantEmploymentEmail && (
                  <p className="errorText">
                    {formik.errors.secondApplicantEmploymentEmail}
                  </p>
                )}
            </div>
          </div>
          <div className="inputRow">
            <div>
              <Input
                type="text"
                label="Position"
                name="secondApplicantPosition"
                border={
                  formik.touched.secondApplicantPosition &&
                  formik.errors.secondApplicantPosition &&
                  "2px solid var(--redColor)"
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.secondApplicantPosition}
                required
              />
              {formik.touched.secondApplicantPosition &&
                formik.errors.secondApplicantPosition && (
                  <p className="errorText">
                    {formik.errors.secondApplicantPosition}
                  </p>
                )}
            </div>
            <div>
              <Input
                type="text"
                label="Income Type"
                name="secondApplicantIncomeType"
                border={
                  formik.touched.secondApplicantIncomeType &&
                  formik.errors.secondApplicantIncomeType &&
                  "2px solid var(--redColor)"
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.secondApplicantIncomeType}
                required
              />
              {formik.touched.secondApplicantIncomeType &&
                formik.errors.secondApplicantIncomeType && (
                  <p className="errorText">
                    {formik.errors.secondApplicantIncomeType}
                  </p>
                )}
            </div>
            <div>
              <CurrencyInputTag
            name="secondApplicantGrossAnnualIncome"
            label="Gross Annual Income"
            onChange={(e) => {
              const value = e.target.value;
              const parsedValue = value.replace(/[^\d.]/gi, "");
              const currencyInt = parseFloat(parsedValue);
              const currencyValue = currencyInt.toLocaleString();
              formik.setFieldValue('secondApplicantGrossAnnualIncome', currencyValue)
            }}
            onBlur={formik.handleBlur}
          />
            </div>
          </div>
          <div className="inputRow">
            <div>
              <Input
                type="text"
                label="Additional Job"
                name="secondApplicantAdditionalJob"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.secondApplicantAdditionalJob}
              />
            </div>
            <div>
              <Input
                type="text"
                label="Income Type"
                name="secondApplicantAdditionalIncomeType"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.secondApplicantAdditionalIncomeType}
              />
            </div>
            <div>
              <CurrencyInputTag
            name="secondApplicantAdditionalGrossIncome"
            label="Gross Income"
            onChange={(e) => {
              const value = e.target.value;
              const parsedValue = value.replace(/[^\d.]/gi, "");
              const currencyInt = parseFloat(parsedValue);
              const currencyValue = currencyInt.toLocaleString();
              formik.setFieldValue('secondApplicantAdditionalGrossIncome', currencyValue)
            }}
            onBlur={formik.handleBlur}
          />
            </div>
          </div>
          <div className="inputRow">
            <div>
              <Input
                type="text"
                label="Previous Employer(complete only if current is less than 3 years)"
                name="secondApplicantPreviousEmployer"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.secondApplicantPreviousEmployer}
              />
            </div>
            <div>
              <Input
                type="text"
                label="Duration Employed"
                name="secondApplicantPreviousDurationEmployed"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.secondApplicantPreviousDurationEmployed}
              />
            </div>
          </div>
          <div className="inputRow">
            <div>
              <Input
                type="number"
                label="Phone"
                name="secondApplicantPreviousPhone"
                border={
                  formik.touched.secondApplicantPreviousPhone &&
                  formik.errors.secondApplicantPreviousPhone &&
                  "2px solid var(--redColor)"
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.secondApplicantPreviousPhone}
              />
              {formik.touched.secondApplicantPreviousPhone &&
                formik.errors.secondApplicantPreviousPhone && (
                  <p className="errorText">
                    {formik.errors.secondApplicantPreviousPhone}
                  </p>
                )}
            </div>
            <div>
              <Input
                type="email"
                label="Email"
                border={
                  formik.touched.secondApplicantPreviousEmail &&
                  formik.errors.secondApplicantPreviousEmail &&
                  "2px solid var(--redColor)"
                }
                name="secondApplicantPreviousEmail"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.secondApplicantPreviousEmail}
              />
              {formik.touched.secondApplicantPreviousEmail &&
                formik.errors.secondApplicantPreviousEmail && (
                  <p className="errorText">
                    {formik.errors.secondApplicantPreviousEmail}
                  </p>
                )}
            </div>
          </div>
          <div className="inputRow">
            <div>
              <Input
                type="text"
                label="Position"
                name="secondApplicantPreviousPosition"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.secondApplicantPreviousPosition}
              />
            </div>
            <div>
              <Input
                type="text"
                label="Income Type"
                name="secondApplicantPreviousIncomeType"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.secondApplicantPreviousIncomeType}
              />
            </div>
            <div>
              <CurrencyInputTag
            name="secondApplicantPreviousGrossAnnualIncome"
            label="Gross Annual Income"
            onChange={(e) => {
              const value = e.target.value;
              const parsedValue = value.replace(/[^\d.]/gi, "");
              const currencyInt = parseFloat(parsedValue);
              const currencyValue = currencyInt.toLocaleString();
              formik.setFieldValue('secondApplicantPreviousGrossAnnualIncome', currencyValue)
            }}
            onBlur={formik.handleBlur}
          />
            </div>
          </div>
        </>
      ) : null}
      <div className="navBtnContainer">
        <Button
          type="button"
          label="Back"
          maxWidth="15rem"
          onClick={props.back}
        />
        <Button
          type="submit"
          label="Next"
          maxWidth="15rem"
          onClick={() => {
            if (Object.keys(formik.errors).length === 0) errorHandler();
          }}
        />
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="dark"
      />
    </form>
  );
};

export default StepTwo;
