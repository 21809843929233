export const initialValues = {
    subjectProperty: "",
    totalOtherRealEstate: "",
    cashInBank: "",
    business: "",
    stockBondsInvestments: "",
    householdEffects: "",
    automobilesTrailers: "",
    other: "",
    totalAssets: "",
    mortgageLoanBalance: "",
    mortgageLoanPayment: "",
    totalOtherMortgageBalance: "",
    totalOtherMortgagePayment: "",
    personalLoansBalance: "",
    personalLoansPayment: "",
    creditCardsLinesBalance: "",
    creditCardsLinesPayment: "",
    studentLoanBalance: "",
    studentLoanPayment: "",
    autoLoansLeasesBalance: "",
    autoLoansLeasesPayment: "",
    otherBalance: "",
    otherPayment: "",
    totalLiabilitiesBalance: "",
    totalLiabilitiesPayment: "",
    totalNetWorth: "",
    
}