import { useState, useEffect } from "react";
import "./App.scss";
import SiteLogo from "./assets/siteLogo.png";
import { StepOne, StepTwo, StepThree, StepFour } from "./parts";

function App() {
  const [step, setStep] = useState(1);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [numberOfApplicants, setNumberOfApplicants] = useState(0);
  const [formObj, setFormObj] = useState({});
  const nextHandler = () => {
    if (step === 1) {
      setFormObj({ ...formObj, numberOfApplicants: numberOfApplicants });
      setStep(step + 1);
    } else {
      setStep(step + 1);
    }
  };
  const backHandler = () => setStep(step - 1);
  console.log("step", step);
  console.log("Number of applicants", numberOfApplicants);
  console.log("form values", formObj);

  // http://localhost:4000/send
  // https://mmpapplication.herokuapp.com/send

  useEffect(() => {
    if (step === 5) {
      fetch("https://mmpapplication.herokuapp.com/send", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formObj),
    }).then(function (data) {
      setSendingEmail(false);
      setStep(step + 1);
    });
    }
  }, [formObj, step]);

  return (
    <div className="App">
      <div className="mortgageProContainer">
        <div className="mortgageFormContainer">
          <img src={SiteLogo} alt="My Mortgage Pro" />
          <div className="noteContainer">
            <p>
              <strong style={{ color: "var(--redColor)" }}>NOTE:</strong> Please
              omit questions you do not understand or do not know.
            </p>
          </div>
          <div className="formContainer">
            {step === 1 && (
              <StepOne
                numberOfApplicants={(val) => {
                  setNumberOfApplicants(val);
                }}
                next={nextHandler}
              />
            )}
            {step === 2 && (
              <StepTwo
                stepTwoFormValues={(val) => {
                  setFormObj({ ...formObj, ...val });
                }}
                next={nextHandler}
                back={backHandler}
                numberOfApplicants={numberOfApplicants}
              />
            )}
            {step === 3 && (
              <StepThree
                stepThreeFormValues={(val) => {
                  setFormObj({ ...formObj, ...val });
                }}
                next={nextHandler}
                back={backHandler}
                numberOfApplicants={numberOfApplicants}
              />
            )}
            {step === 4 && (
              <StepFour
                stepFourFormValues={(val) => {
                  setFormObj({ ...formObj, ...val });
                }}
                next={nextHandler}
                back={backHandler}
                loading={sendingEmail}
              />
            )}
            {step === 5 && (
              <h2 className="lastMsg">Wait, Your response is being submitted....</h2>
            )}
            {step === 6 && (
              <h2 className="lastMsg">Thank you for your response. We will get back to you soon.</h2>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
