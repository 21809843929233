import * as Yup from "yup";

export const initialValues = {
  firstApplicantName: "",
  firstApplicantSin: "",
  firstApplicantDob: "",
  firstApplicantCurrentAddress: "",
  firstApplicantDurationAtAddress: "",
  firstApplicantPreviousAddress: "",
  firstApplicantMaritalStatus: "",
  firstApplicantDependents: "",
  firstApplicantHomePhone: "",
  firstApplicantOfficePhone: "",
  firstApplicantCellPhone: "",
  firstApplicantPrimaryEmail: "",
  firstApplicantSecondaryEmail: "",
  firstApplicantCurrentEmployer: "",
  firstApplicantDurationEmployed: "",
  firstApplicantEmploymentAddress: "",
  firstApplicantEmploymentPhone: "",
  firstApplicantEmploymentEmail: "",
  firstApplicantPosition: "",
  firstApplicantIncomeType: "",
  firstApplicantGrossAnnualIncome: "",
  firstApplicantAdditionalJob: "",
  firstApplicantAdditionalIncomeType: "",
  firstApplicantAdditionalGrossIncome: "",
  firstApplicantPreviousEmployer: "",
  firstApplicantPreviousDurationEmployed: "",
  firstApplicantPreviousPhone: "",
  firstApplicantPreviousEmail: "",
  firstApplicantPreviousPosition: "",
  firstApplicantPreviousIncomeType: "",
  firstApplicantPreviousGrossAnnualIncome: "",
};

export const secondaryInitialValues = {
  firstApplicantName: "",
  firstApplicantSin: "",
  firstApplicantDob: "",
  firstApplicantCurrentAddress: "",
  firstApplicantDurationAtAddress: "",
  firstApplicantPreviousAddress: "",
  firstApplicantMaritalStatus: "",
  firstApplicantDependents: "",
  firstApplicantHomePhone: "",
  firstApplicantOfficePhone: "",
  firstApplicantCellPhone: "",
  firstApplicantPrimaryEmail: "",
  firstApplicantSecondaryEmail: "",
  firstApplicantCurrentEmployer: "",
  firstApplicantDurationEmployed: "",
  firstApplicantEmploymentAddress: "",
  firstApplicantEmploymentPhone: "",
  firstApplicantEmploymentEmail: "",
  firstApplicantPosition: "",
  firstApplicantIncomeType: "",
  firstApplicantGrossAnnualIncome: "",
  firstApplicantAdditionalJob: "",
  firstApplicantAdditionalIncomeType: "",
  firstApplicantAdditionalGrossIncome: "",
  firstApplicantPreviousEmployer: "",
  firstApplicantPreviousDurationEmployed: "",
  firstApplicantPreviousPhone: "",
  firstApplicantPreviousEmail: "",
  firstApplicantPreviousPosition: "",
  firstApplicantPreviousIncomeType: "",
  firstApplicantPreviousGrossAnnualIncome: "",
  secondApplicantName: "",
  secondApplicantSin: "",
  secondApplicantDob: "",
  secondApplicantCurrentAddress: "",
  secondApplicantDurationAtAddress: "",
  secondApplicantPreviousAddress: "",
  secondApplicantMaritalStatus: "",
  secondApplicantDependents: "",
  secondApplicantHomePhone: "",
  secondApplicantOfficePhone: "",
  secondApplicantCellPhone: "",
  secondApplicantPrimaryEmail: "",
  secondApplicantSecondaryEmail: "",
  secondApplicantCurrentEmployer: "",
  secondApplicantDurationEmployed: "",
  secondApplicantEmploymentAddress: "",
  secondApplicantEmploymentPhone: "",
  secondApplicantEmploymentEmail: "",
  secondApplicantPosition: "",
  secondApplicantIncomeType: "",
  secondApplicantGrossAnnualIncome: "",
  secondApplicantAdditionalJob: "",
  secondApplicantAdditionalIncomeType: "",
  secondApplicantAdditionalGrossIncome: "",
  secondApplicantPreviousEmployer: "",
  secondApplicantPreviousDurationEmployed: "",
  secondApplicantPreviousPhone: "",
  secondApplicantPreviousEmail: "",
  secondApplicantPreviousPosition: "",
  secondApplicantPreviousIncomeType: "",
  secondApplicantPreviousGrossAnnualIncome: "",
};

export const secondValidationSchema = Yup.object({
  firstApplicantName: Yup.string()
    .matches(/^[a-zA-Z\s]*$/, "Only Alphabets are allowed")
    .required("This is a required field"),
  firstApplicantDob: Yup.string()
    .matches(/^[0-9.\-/]+$/, "Only numbers are allowed")
    .min(10, "Invalid Date")
    .max(10, "Invalid Date")
    .test("len", "Invalid Date", (val) => {
      if (val) {
        const dateArray = val.split("/");
        //date index
        if (Number(dateArray[0]) > 31) {
          return false;
        }
        if (Number(dateArray[1]) > 12) {
          return false;
        }
      }
      return true;
    })
    .test("len", "Please select a valid date of birth", (val) => {
      if (val) {
        const dateArray = val.split("/");

        if (dateArray[0] && dateArray[1] && dateArray[2]) {
          let currentDate = new Date();
          let appliedDate = new Date(
            `${Number(dateArray[1])}/${Number(dateArray[0])}/ ${Number(
              dateArray[2]
            )}`
          );

          if (currentDate < appliedDate) return false;
        }
      }
      return true;
    })

    .required("This is a required field"),
  firstApplicantCurrentAddress: Yup.string().required(
    "This is a required field"
  ),
  firstApplicantDurationAtAddress: Yup.string().required(
    "This is a required field"
  ),
  firstApplicantMaritalStatus: Yup.string().required(
    "This is a required field"
  ),
  firstApplicantHomePhone: Yup.string("only numbers are allowed")
    .min(10, "Number must be atleast 10 digits")
    .required("This is a required field"),
  firstApplicantOfficePhone: Yup.string("only numbers are allowed").min(
    10,
    "Number must be atleast 10 digits"
  ),
  firstApplicantCellPhone: Yup.string("only numbers are allowed")
    .min(10, "Number must be atleast 10 digits")
    .required("This is a required field"),
  firstApplicantPrimaryEmail: Yup.string()
    .email("Email is invalid")
    .required("This is a required field"),
  firstApplicantSecondaryEmail: Yup.string().email("Email is invalid"),
  firstApplicantCurrentEmployer: Yup.string().required(
    "This is a required field"
  ),
  firstApplicantDurationEmployed: Yup.string().required(
    "This is a required field"
  ),
  firstApplicantEmploymentPhone: Yup.string("only numbers are allowed")
    .min(10, "Number must be atleast 10 digits")
    .required("This is a required field"),
  firstApplicantEmploymentEmail: Yup.string()
    .email("Email is invalid")
    .required("This is a required field"),
  firstApplicantPosition: Yup.string().required("This is a required field"),
  firstApplicantIncomeType: Yup.string().required("This is a required field"),
  firstApplicantPreviousPhone: Yup.string("only numbers are allowed").min(
    10,
    "Number must be atleast 10 digits"
  ),
  firstApplicantPreviousEmail: Yup.string().email("Email is invalid"),
  secondApplicantName: Yup.string()
    .matches(/^[a-zA-Z\s]*$/, "Only Alphabets are allowed")
    .required("This is a required field"),
  secondApplicantDob: Yup.string()
    .matches(/^[0-9.\-/]+$/, "Only numbers are allowed")
    .min(10, "Invalid Date")
    .max(10, "Invalid Date")
    .test("len", "Invalid Date", (val) => {
      if (val) {
        const dateArray = val.split("/");
        //date index
        if (Number(dateArray[0]) > 31) {
          return false;
        }
        if (Number(dateArray[1]) > 12) {
          return false;
        }
      }
      return true;
    })
    .test("len", "Please select a valid date of birth", (val) => {
      if (val) {
        const dateArray = val.split("/");

        if (dateArray[0] && dateArray[1] && dateArray[2]) {
          let currentDate = new Date();
          let appliedDate = new Date(
            `${Number(dateArray[1])}/${Number(dateArray[0])}/ ${Number(
              dateArray[2]
            )}`
          );

          if (currentDate < appliedDate) return false;
        }
      }
      return true;
    })

    .required("This is a required field"),
  secondApplicantCurrentAddress: Yup.string().required(
    "This is a required field"
  ),
  secondApplicantDurationAtAddress: Yup.string().required(
    "This is a required field"
  ),
  secondApplicantMaritalStatus: Yup.string().required(
    "This is a required field"
  ),
  secondApplicantHomePhone: Yup.string("only numbers are allowed")
    .min(10, "Number must be atleast 10 digits")
    .required("This is a required field"),
  secondApplicantOfficePhone: Yup.string("only numbers are allowed").min(
    10,
    "Number must be atleast 10 digits"
  ),
  secondApplicantCellPhone: Yup.string("only numbers are allowed")
    .min(10, "Number must be atleast 10 digits")
    .required("This is a required field"),
  secondApplicantPrimaryEmail: Yup.string()
    .email("Email is invalid")
    .required("This is a required field"),
  secondApplicantSecondaryEmail: Yup.string().email("Email is invalid"),
  secondApplicantCurrentEmployer: Yup.string().required(
    "This is a required field"
  ),
  secondApplicantDurationEmployed: Yup.string().required(
    "This is a required field"
  ),
  secondApplicantEmploymentPhone: Yup.string("only numbers are allowed")
    .min(10, "Number must be atleast 10 digits")
    .required("This is a required field"),
  secondApplicantEmploymentEmail: Yup.string()
    .email("Email is invalid")
    .required("This is a required field"),
  secondApplicantPosition: Yup.string().required("This is a required field"),
  secondApplicantIncomeType: Yup.string().required("This is a required field"),
  secondApplicantPreviousPhone: Yup.string("only numbers are allowed").min(
    10,
    "Number must be atleast 10 digits"
  ),
  secondApplicantPreviousEmail: Yup.string().email("Email is invalid"),
});

export const validationSchema = Yup.object({
  firstApplicantName: Yup.string()
    .matches(/^[a-zA-Z\s]*$/, "Only Alphabets are allowed")
    .required("This is a required field"),
  firstApplicantDob: Yup.string()
    .matches(/^[0-9.\-/]+$/, "Only numbers are allowed")
    .min(10, "Invalid Date")
    .max(10, "Invalid Date")
    .test("len", "Invalid Date", (val) => {
      if (val) {
        const dateArray = val.split("/");
        //date index
        if (Number(dateArray[0]) > 31) {
          return false;
        }
        if (Number(dateArray[1]) > 12) {
          return false;
        }
      }
      return true;
    })
    .test("len", "Please select a valid date of birth", (val) => {
      if (val) {
        const dateArray = val.split("/");

        if (dateArray[0] && dateArray[1] && dateArray[2]) {
          let currentDate = new Date();
          let appliedDate = new Date(
            `${Number(dateArray[1])}/${Number(dateArray[0])}/ ${Number(
              dateArray[2]
            )}`
          );

          if (currentDate < appliedDate) return false;
        }
      }
      return true;
    })

    .required("This is a required field"),
  firstApplicantCurrentAddress: Yup.string().required(
    "This is a required field"
  ),
  firstApplicantDurationAtAddress: Yup.string().required(
    "This is a required field"
  ),
  firstApplicantMaritalStatus: Yup.string().required(
    "This is a required field"
  ),
  firstApplicantHomePhone: Yup.string("only numbers are allowed")
    .min(10, "Number must be atleast 10 digits")
    .required("This is a required field"),
  firstApplicantOfficePhone: Yup.string("only numbers are allowed").min(
    10,
    "Number must be atleast 10 digits"
  ),
  firstApplicantCellPhone: Yup.string("only numbers are allowed")
    .min(10, "Number must be atleast 10 digits")
    .required("This is a required field"),
  firstApplicantPrimaryEmail: Yup.string()
    .email("Email is invalid")
    .required("This is a required field"),
  firstApplicantSecondaryEmail: Yup.string().email("Email is invalid"),
  firstApplicantCurrentEmployer: Yup.string().required(
    "This is a required field"
  ),
  firstApplicantDurationEmployed: Yup.string().required(
    "This is a required field"
  ),
  firstApplicantEmploymentPhone: Yup.string("only numbers are allowed")
    .min(10, "Number must be atleast 10 digits")
    .required("This is a required field"),
  firstApplicantEmploymentEmail: Yup.string()
    .email("Email is invalid")
    .required("This is a required field"),
  firstApplicantPosition: Yup.string().required("This is a required field"),
  firstApplicantIncomeType: Yup.string().required("This is a required field"),
  firstApplicantPreviousPhone: Yup.string("only numbers are allowed").min(
    10,
    "Number must be atleast 10 digits"
  ),
  firstApplicantPreviousEmail: Yup.string().email("Email is invalid"),
});
