import CurrencyInput from "react-currency-input-field";
import styles from "./style.module.scss";

const CurrencyInputTag = (props) => {
  const style = {
    border: props.border ? props.border : "2px solid var(--whiteColor)",
    borderRadius: "3px",
    height: "35px",
    padding: "0 1.5rem",
    width: "100%",
    fontSize: "1.6rem",
  };
  return (
    <div className={styles.inputContainer}>
      {props.label && (
        <label>
          {props.label}
          {props.required && (
            <span style={{ color: "var(--redColor)" }}>*</span>
          )}
        </label>
      )}
      <CurrencyInput
        prefix="$"
        name={props.name}
        id={props.name}
        data-number-to-fixed="2"
        data-number-stepfactor="100"
        placeholder={props.placeholder}
        allowDecimals
        decimalsLimit="2"
        disableAbbreviations
        className="inputField"
        style={style}
        onChange={props.onChange}
        onBlur={props.onBlur}
        value={props.value}
        // onChange={(e) => {
        //   const value = e.target.value;
        //   const parsedValue = value.replace(/[^\d.]/gi, "");
        //   const currencyInt = parseInt(parsedValue);
        //   const currencyValue = currencyInt.toLocaleString();
        //   setAnnualIncome(currencyValue);
        // }}
      />
    </div>
  );
};

export default CurrencyInputTag;
