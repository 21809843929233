import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Button, CurrencyInputTag } from "../../../components";
import { initialValues } from "../../../utils/stepThreeValidation";
import styles from "./style.module.scss";
const StepThree = (props) => {
  const [totalAssets, setTotalAssets] = useState(0);
  const [totalLiabilitiesBalance, setTotalLiabilitiesBalance] = useState(0);
  const [totalLiabilitiesPayment, setTotalLiabilitiesPayment] = useState(0);
  const [subjectProperty, setSubjectProperty] = useState(0);
  const [totalOtherRealEstate, setTotalOtherRealEstate] = useState(0);
  const [cashInBank, setCashInBank] = useState(0);
  const [business, setBusiness] = useState(0);
  const [stockBondsInvestments, setStockBondsInvestments] = useState(0);
  const [householdEffects, setHouseholdEffects] = useState(0);
  const [automobilesTrailers, setAutomobilesTrailers] = useState(0);
  const [other, setOther] = useState(0);
  const [mortgageLoanBalance, setMortgageLoanBalance] = useState(0);
  const [mortgageLoanPayment, setMortgageLoanPayment] = useState(0);
  const [totalOtherMortgageBalance, setTotalOtherMortgageBalance] = useState(0);
  const [totalOtherMortgagePayment, setTotalOtherMortgagePayment] = useState(0);
  const [personalLoansBalance, setPersonalLoansBalance] = useState(0);
  const [personalLoansPayment, setPersonalLoansPayment] = useState(0);
  const [creditCardsLinesBalance, setCreditCardsLinesBalance] = useState(0);
  const [creditCardsLinesPayment, setCreditCardsLinesPayment] = useState(0);
  const [studentLoanBalance, setStudentLoanBalance] = useState(0);
  const [studentLoanPayment, setStudentLoanPayment] = useState(0);
  const [autoLoansLeasesBalance, setAutoLoansLeasesBalance] = useState(0);
  const [autoLoansLeasesPayment, setAutoLoansLeasesPayment] = useState(0);
  const [otherBalance, setOtherBalance] = useState(0);
  const [otherPayment, setOtherPayment] = useState(0);
  const [totalNetWorth, setTotalNetWorth] = useState(0);
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: function (values) {
      props.stepThreeFormValues({
        ...values,
        totalAssets: totalAssets.toLocaleString(),
        totalLiabilitiesBalance: totalLiabilitiesBalance.toLocaleString(),
        totalLiabilitiesPayment: totalLiabilitiesPayment.toLocaleString(),
        totalNetWorth: totalNetWorth.toLocaleString(),
      });
      props.next();
    },
  });

  // Total Assets

  useEffect(() => {
    let totalAssetsAdd =
      subjectProperty +
      totalOtherRealEstate +
      cashInBank +
      business +
      stockBondsInvestments +
      householdEffects +
      automobilesTrailers +
      other;
    setTotalAssets(totalAssetsAdd);
  }, [
    subjectProperty,
    totalOtherRealEstate,
    cashInBank,
    business,
    stockBondsInvestments,
    householdEffects,
    automobilesTrailers,
    other,
  ]);

  // Total Liabilities

  useEffect(() => {
    let totalLiabilitiesBalance =
      mortgageLoanBalance +
      totalOtherMortgageBalance +
      personalLoansBalance +
      creditCardsLinesBalance +
      studentLoanBalance +
      autoLoansLeasesBalance +
      otherBalance;
    let totalLiabilitiesPayment =
      mortgageLoanPayment +
      totalOtherMortgagePayment +
      personalLoansPayment +
      creditCardsLinesPayment +
      studentLoanPayment +
      autoLoansLeasesPayment +
      otherPayment;
    setTotalLiabilitiesBalance(totalLiabilitiesBalance);
    setTotalLiabilitiesPayment(totalLiabilitiesPayment);
  }, [
    mortgageLoanBalance,
    mortgageLoanPayment,
    totalOtherMortgageBalance,
    totalOtherMortgagePayment,
    personalLoansBalance,
    personalLoansPayment,
    creditCardsLinesBalance,
    creditCardsLinesPayment,
    studentLoanBalance,
    studentLoanPayment,
    autoLoansLeasesBalance,
    autoLoansLeasesPayment,
    otherBalance,
    otherPayment,
  ]);

  // Total Net Worth

  useEffect(() => {
    let totalNetWorth = totalAssets - totalLiabilitiesBalance;
    setTotalNetWorth(totalNetWorth);
  }, [totalAssets, totalLiabilitiesBalance]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={styles.formInner}>
        <div className={styles.formInnerColOne}>
          <div className={styles.headerTitle}>
            <h2>Assets</h2>
            <h2>Values</h2>
          </div>
          <div className="inputRow">
            <CurrencyInputTag
              name="subjectProperty"
              label="Subject Property"
              onChange={(e) => {
                const value = e.target.value;
                const parsedValue = value.replace(/[^\d.]/gi, "");
                const currencyInt = parseFloat(parsedValue);
                const currencyValue = currencyInt.toLocaleString();
                formik.setFieldValue("subjectProperty", currencyValue);
                setSubjectProperty(currencyInt);
              }}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="inputRow">
            <CurrencyInputTag
              name="totalOtherRealEstate"
              label="Total Other Real Estate"
              onChange={(e) => {
                const value = e.target.value;
                const parsedValue = value.replace(/[^\d.]/gi, "");
                const currencyInt = parseFloat(parsedValue);
                const currencyValue = currencyInt.toLocaleString();
                formik.setFieldValue("totalOtherRealEstate", currencyValue);
                setTotalOtherRealEstate(currencyInt);
              }}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="inputRow">
            <CurrencyInputTag
              name="cashInBank"
              label="Cash in Bank"
              onChange={(e) => {
                const value = e.target.value;
                const parsedValue = value.replace(/[^\d.]/gi, "");
                const currencyInt = parseFloat(parsedValue);
                const currencyValue = currencyInt.toLocaleString();
                formik.setFieldValue("cashInBank", currencyValue);
                setCashInBank(currencyInt);
              }}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="inputRow">
            <CurrencyInputTag
              name="business"
              label="Business"
              onChange={(e) => {
                const value = e.target.value;
                const parsedValue = value.replace(/[^\d.]/gi, "");
                const currencyInt = parseFloat(parsedValue);
                const currencyValue = currencyInt.toLocaleString();
                formik.setFieldValue("business", currencyValue);
                setBusiness(currencyInt);
              }}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="inputRow">
            <CurrencyInputTag
              name="stockBondsInvestments"
              label="Stock, Bonds, Investments, Etc."
              onChange={(e) => {
                const value = e.target.value;
                const parsedValue = value.replace(/[^\d.]/gi, "");
                const currencyInt = parseFloat(parsedValue);
                const currencyValue = currencyInt.toLocaleString();
                formik.setFieldValue("stockBondsInvestments", currencyValue);
                setStockBondsInvestments(currencyInt);
              }}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="inputRow">
            <CurrencyInputTag
              name="householdEffects"
              label="Household Effects"
              onChange={(e) => {
                const value = e.target.value;
                const parsedValue = value.replace(/[^\d.]/gi, "");
                const currencyInt = parseFloat(parsedValue);
                const currencyValue = currencyInt.toLocaleString();
                formik.setFieldValue("householdEffects", currencyValue);
                setHouseholdEffects(currencyInt);
              }}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="inputRow">
            <CurrencyInputTag
              name="automobilesTrailers"
              label="Automobiles, Trailers, Etc."
              onChange={(e) => {
                const value = e.target.value;
                const parsedValue = value.replace(/[^\d.]/gi, "");
                const currencyInt = parseFloat(parsedValue);
                const currencyValue = currencyInt.toLocaleString();
                formik.setFieldValue("automobilesTrailers", currencyValue);
                setAutomobilesTrailers(currencyInt);
              }}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="inputRow">
            <CurrencyInputTag
              name="other"
              label="Others"
              onChange={(e) => {
                const value = e.target.value;
                const parsedValue = value.replace(/[^\d.]/gi, "");
                const currencyInt = parseFloat(parsedValue);
                const currencyValue = currencyInt.toLocaleString();
                formik.setFieldValue("other", currencyValue);
                setOther(currencyInt);
              }}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="inputRow">
            <div className={styles.disabledInput}>
              <label>Total Assets</label>
              <div className={styles.totalContainer}>${totalAssets.toLocaleString()}</div>
            </div>
          </div>
          <div className="inputRow">
            <div className={styles.disabledInput}>
              <label>Total Net Worth</label>
              <div className={styles.totalContainer}>${totalNetWorth.toLocaleString()}</div>
            </div>
          </div>
        </div>
        <div className={styles.formInnerColTwo}>
          <div className={styles.headerTitle}>
            <h2>Liabilities</h2>
            <h2>Balance</h2>
            <h2>Payment</h2>
          </div>
          <div className="inputRow">
            <p>Mortgage/Loan</p>
            <CurrencyInputTag
              name="mortgageLoanBalance"
              onChange={(e) => {
                const value = e.target.value;
                const parsedValue = value.replace(/[^\d.]/gi, "");
                const currencyInt = parseFloat(parsedValue);
                const currencyValue = currencyInt.toLocaleString();
                formik.setFieldValue("mortgageLoanBalance", currencyValue);
                setMortgageLoanBalance(currencyInt);
              }}
              onBlur={formik.handleBlur}
            />
            <CurrencyInputTag
              name="mortgageLoanPayment"
              onChange={(e) => {
                const value = e.target.value;
                const parsedValue = value.replace(/[^\d.]/gi, "");
                const currencyInt = parseFloat(parsedValue);
                const currencyValue = currencyInt.toLocaleString();
                formik.setFieldValue("mortgageLoanPayment", currencyValue);
                setMortgageLoanPayment(currencyInt);
              }}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="inputRow">
            <p>Total Other Mortgage(s)</p>
            <CurrencyInputTag
              name="totalOtherMortgageBalance"
              onChange={(e) => {
                const value = e.target.value;
                const parsedValue = value.replace(/[^\d.]/gi, "");
                const currencyInt = parseFloat(parsedValue);
                const currencyValue = currencyInt.toLocaleString();
                formik.setFieldValue(
                  "totalOtherMortgageBalance",
                  currencyValue
                );
                setTotalOtherMortgageBalance(currencyInt);
              }}
              onBlur={formik.handleBlur}
            />
            <CurrencyInputTag
              name="totalOtherMortgagePayment"
              onChange={(e) => {
                const value = e.target.value;
                const parsedValue = value.replace(/[^\d.]/gi, "");
                const currencyInt = parseFloat(parsedValue);
                const currencyValue = currencyInt.toLocaleString();
                formik.setFieldValue(
                  "totalOtherMortgagePayment",
                  currencyValue
                );
                setTotalOtherMortgagePayment(currencyInt);
              }}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="inputRow">
            <p>Personal Loans</p>
            <CurrencyInputTag
              name="personalLoansBalance"
              onChange={(e) => {
                const value = e.target.value;
                const parsedValue = value.replace(/[^\d.]/gi, "");
                const currencyInt = parseFloat(parsedValue);
                const currencyValue = currencyInt.toLocaleString();
                formik.setFieldValue("personalLoansBalance", currencyValue);
                setPersonalLoansBalance(currencyInt);
              }}
              onBlur={formik.handleBlur}
            />
            <CurrencyInputTag
              name="personalLoansPayment"
              onChange={(e) => {
                const value = e.target.value;
                const parsedValue = value.replace(/[^\d.]/gi, "");
                const currencyInt = parseFloat(parsedValue);
                const currencyValue = currencyInt.toLocaleString();
                formik.setFieldValue("personalLoansPayment", currencyValue);
                setPersonalLoansPayment(currencyInt);
              }}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="inputRow">
            <p>Credit Cards/Lines</p>
            <CurrencyInputTag
              name="creditCardsLinesBalance"
              onChange={(e) => {
                const value = e.target.value;
                const parsedValue = value.replace(/[^\d.]/gi, "");
                const currencyInt = parseFloat(parsedValue);
                const currencyValue = currencyInt.toLocaleString();
                formik.setFieldValue("creditCardsLinesBalance", currencyValue);
                setCreditCardsLinesBalance(currencyInt);
              }}
              onBlur={formik.handleBlur}
            />
            <CurrencyInputTag
              name="creditCardsLinesPayment"
              onChange={(e) => {
                const value = e.target.value;
                const parsedValue = value.replace(/[^\d.]/gi, "");
                const currencyInt = parseFloat(parsedValue);
                const currencyValue = currencyInt.toLocaleString();
                formik.setFieldValue("creditCardsLinesPayment", currencyValue);
                setCreditCardsLinesPayment(currencyInt);
              }}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="inputRow">
            <p>Student Loans</p>
            <CurrencyInputTag
              name="studentLoanBalance"
              onChange={(e) => {
                const value = e.target.value;
                const parsedValue = value.replace(/[^\d.]/gi, "");
                const currencyInt = parseFloat(parsedValue);
                const currencyValue = currencyInt.toLocaleString();
                formik.setFieldValue("studentLoanBalance", currencyValue);
                setStudentLoanBalance(currencyInt);
              }}
              onBlur={formik.handleBlur}
            />
            <CurrencyInputTag
              name="studentLoanPayment"
              onChange={(e) => {
                const value = e.target.value;
                const parsedValue = value.replace(/[^\d.]/gi, "");
                const currencyInt = parseFloat(parsedValue);
                const currencyValue = currencyInt.toLocaleString();
                formik.setFieldValue("studentLoanPayment", currencyValue);
                setStudentLoanPayment(currencyInt);
              }}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="inputRow">
            <p>Auto Loans/Leases</p>
            <CurrencyInputTag
              name="autoLoansLeasesBalance"
              onChange={(e) => {
                const value = e.target.value;
                const parsedValue = value.replace(/[^\d.]/gi, "");
                const currencyInt = parseFloat(parsedValue);
                const currencyValue = currencyInt.toLocaleString();
                formik.setFieldValue("autoLoansLeasesBalance", currencyValue);
                setAutoLoansLeasesBalance(currencyInt);
              }}
              onBlur={formik.handleBlur}
            />
            <CurrencyInputTag
              name="autoLoansLeasesPayment"
              onChange={(e) => {
                const value = e.target.value;
                const parsedValue = value.replace(/[^\d.]/gi, "");
                const currencyInt = parseFloat(parsedValue);
                const currencyValue = currencyInt.toLocaleString();
                formik.setFieldValue("autoLoansLeasesPayment", currencyValue);
                setAutoLoansLeasesPayment(currencyInt);
              }}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="inputRow">
            <p>Other</p>
            <CurrencyInputTag
              name="otherBalance"
              onChange={(e) => {
                const value = e.target.value;
                const parsedValue = value.replace(/[^\d.]/gi, "");
                const currencyInt = parseFloat(parsedValue);
                const currencyValue = currencyInt.toLocaleString();
                formik.setFieldValue("otherBalance", currencyValue);
                setOtherBalance(currencyInt);
              }}
              onBlur={formik.handleBlur}
            />
            <CurrencyInputTag
              name="otherPayment"
              onChange={(e) => {
                const value = e.target.value;
                const parsedValue = value.replace(/[^\d.]/gi, "");
                const currencyInt = parseFloat(parsedValue);
                const currencyValue = currencyInt.toLocaleString();
                formik.setFieldValue("otherPayment", currencyValue);
                setOtherPayment(currencyInt);
              }}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="inputRow">
            <p>Total Liabilities</p>
            <div className={styles.totalContainer}>
              ${totalLiabilitiesBalance.toLocaleString()}
            </div>
            <div className={styles.totalContainer}>
              ${totalLiabilitiesPayment.toLocaleString()}
            </div>
          </div>
        </div>
      </div>
      <div className="navBtnContainer">
        <Button
          type="button"
          label="Back"
          maxWidth="15rem"
          onClick={props.back}
        />
        <Button type="submit" label="Next" maxWidth="15rem" />
      </div>
    </form>
  );
};

export default StepThree;
