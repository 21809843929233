const Button = (props) => {

    const style = {
        width: props.width ? props.width : "100%",
        maxWidth: props.maxWidth ? props.maxWidth : "100%",
        border: props.border ? props.border : "1px solid var(--primaryColor)",
        backgroundColor: props.backgroundColor ? props.backgroundColor : "var(--primaryColor)",
        color: props.color ? props.color : "var(--whiteColor)",
        fontSize: props.fontSize ? props.fontSize : "2rem",
        fontWeight: "600",  
        padding: props.padding ? props.padding : "1rem 0",
        borderRadius : props.borderRadius ? props.borderRadius : "1rem"   
    }

    return <button type={props.type} onClick={props.onClick} style={style}>{props.loading ? "Submitting.." : props.label}</button>
}

export default Button;