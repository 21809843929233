import { useState } from "react";
import { Button } from "../../../components";
import styles from "./style.module.scss";
import { ToastContainer, toast } from "react-toastify";

const StepOne = (props) => {
  const [selectedOne, setSelectedOne] = useState(false);
  const [selectedTwo, setSelectedTwo] = useState(false);
  const errorHandler = () =>
    toast.error("Please select number of applicant", {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  return (
    <div className={styles.stepOneContainer}>
      <h2>Number of Applicants</h2>
      <div className={styles.btnContainer}>
        <Button
          type="button"
          label="One"
          maxWidth="30rem"
          backgroundColor={selectedOne === true ? "#fff" : "var(--primaryColor)"}
          color={selectedOne === true ? "var(--primaryColor)" : "#fff"}
          onClick={() => {
            props.numberOfApplicants(1);
            if (selectedOne === false) {
              setSelectedOne(true);
              setSelectedTwo(false);
            }
          }}
        />
        <Button
          type="button"
          label="Two"
          maxWidth="30rem"
          backgroundColor={selectedTwo === true ? "#fff" : "var(--primaryColor)"}
          color={selectedTwo === true ? "var(--primaryColor)" : "#fff"}
          onClick={() => {
            props.numberOfApplicants(2);
            if (selectedTwo === false) {
              setSelectedOne(false);
              setSelectedTwo(true);
            }
          }}
        />
      </div>
      <div className="navBtnContainer" style={{ justifyContent: "center" }}>
        <Button
          type="button"
          label="Next"
          maxWidth="15rem"
          onClick={() => {
            if(selectedOne || selectedTwo) {
                props.next();
            } else {
                errorHandler();
            }
          }}
        />
        <ToastContainer
          position="bottom-right"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          theme="dark"
        />
      </div>
    </div>
  );
};

export default StepOne;
