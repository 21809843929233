import styles from "./styles.module.scss";

const Input = (props) => {
  const style = {
    border: props.border ? props.border : "2px solid var(--whiteColor)",
    borderRadius: "3px",
    height: "35px",
    padding: "0 1.5rem",
    width: "100%",
    fontSize: "1.6rem",
  };
  return (
    <div className={styles.inputContainer}>
      {props.label && (
        <label>
          {props.label}
          {props.required && (
            <span style={{ color: "var(--redColor)" }}>*</span>
          )}
        </label>
      )}
      <input
        type={props.type}
        name={props.name}
        id={props.name}
        onChange={props.onChange}
        onBlur={props.onBlur}
        value={props.value}
        style={style}
      />
    </div>
  );
};

export default Input;
