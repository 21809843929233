import { useFormik } from "formik";
import { Input, Button, CurrencyInputTag, Select } from "../../../components";
import {
  initialValues,
  validationSchema,
} from "../../../utils/stepFourValidation";
import styles from "./style.module.scss";
const StepFour = (props) => {
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: function (values) {
      // console.log("chcc", values);
      props.stepFourFormValues({
        ...values,
      });
      props.next();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <h2 className="formtitle" style={{ fontSize: "2.5rem" }}>
        SUBJECT PROPERTY TO BE MORTGAGED, RE-MORTGAGED OR EQUITY TAKE OUT
      </h2>
      <div className="inputRow">
        <div>
          <Input
            type="text"
            label="Address"
            name="subjectPropertyAddress"
            border={
              formik.touched.subjectPropertyAddress &&
              formik.errors.subjectPropertyAddress &&
              "2px solid var(--redColor)"
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.subjectPropertyAddress}
            required
          />
          {formik.touched.subjectPropertyAddress &&
            formik.errors.subjectPropertyAddress && (
              <p className="errorText">
                {formik.errors.subjectPropertyAddress}
              </p>
            )}
        </div>
      </div>
      <div className="inputRow">
        <div>
          <Select
            label="Position"
            name="subjectPropertyPosition"
            defaultText="Position"
            option={[
              {
                value: "First",
                label: "First",
              },
              {
                value: "Second",
                label: "Second",
              },
              {
                value: "Third",
                label: "Third",
              },
            ]}
            border={
              formik.touched.subjectPropertyPosition &&
              formik.errors.subjectPropertyPosition &&
              "2px solid var(--redColor)"
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.subjectPropertyPosition}
            required
          />
          {formik.touched.subjectPropertyPosition &&
            formik.errors.subjectPropertyPosition && (
              <p className="errorText">
                {formik.errors.subjectPropertyPosition}
              </p>
            )}
        </div>
      </div>
      <div className="inputRow">
        <CurrencyInputTag
          name="amountLookingFor"
          label="Amount Looking For"
          onChange={(e) => {
            const value = e.target.value;
            const parsedValue = value.replace(/[^\d.]/gi, "");
            const currencyInt = parseFloat(parsedValue);
            const currencyValue = currencyInt.toLocaleString();
            formik.setFieldValue("amountLookingFor", currencyValue);
          }}
          onBlur={formik.handleBlur}
        />
      </div>
      <div className="inputRow">
        <div>
          <div>
            <Select
              label="Do you have an appraisal?"
              name="doYouHaveAnAppraisal"
              option={[
                {
                  value: "Yes",
                  label: "Yes",
                },
                {
                  value: "No",
                  label: "No",
                },
              ]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.doYouHaveAnAppraisal}
            />
          </div>
        </div>
        <div>
          <CurrencyInputTag
            name="appraisedValue"
            label="Appraised Value"
            onChange={(e) => {
              const value = e.target.value;
              const parsedValue = value.replace(/[^\d.]/gi, "");
              const currencyInt = parseFloat(parsedValue);
              const currencyValue = currencyInt.toLocaleString();
              formik.setFieldValue("appraisedValue", currencyValue);
            }}
            onBlur={formik.handleBlur}
          />
        </div>
      </div>
      <div className="inputRow">
        <div>
          <CurrencyInputTag
            name="annualPropertyText"
            label="Annual Property Tax"
            onChange={(e) => {
              const value = e.target.value;
              const parsedValue = value.replace(/[^\d.]/gi, "");
              const currencyInt = parseFloat(parsedValue);
              const currencyValue = currencyInt.toLocaleString();
              formik.setFieldValue("annualPropertyText", currencyValue);
            }}
            onBlur={formik.handleBlur}
          />
        </div>
        <div>
          <CurrencyInputTag
            name="monthlyMaintFee"
            label="Monthly Maint. Fee"
            onChange={(e) => {
              const value = e.target.value;
              const parsedValue = value.replace(/[^\d.]/gi, "");
              const currencyInt = parseFloat(parsedValue);
              const currencyValue = currencyInt.toLocaleString();
              formik.setFieldValue("monthlyMaintFee", currencyValue);
            }}
            onBlur={formik.handleBlur}
          />
        </div>
        <div>
          <CurrencyInputTag
            name="arrears"
            label="Arrears (if any)"
            onChange={(e) => {
              const value = e.target.value;
              const parsedValue = value.replace(/[^\d.]/gi, "");
              const currencyInt = parseFloat(parsedValue);
              const currencyValue = currencyInt.toLocaleString();
              formik.setFieldValue("arrears", currencyValue);
            }}
            onBlur={formik.handleBlur}
          />
        </div>
      </div>
      <div className="inputRow">
        <div>
          <div>
            <Select
              label="Occupancy"
              name="occupancy"
              option={[
                {
                  value: "Owner",
                  label: "Owner",
                },
                {
                  value: "Tenant",
                  label: "Tenant",
                },
                {
                  value: "Owner and Tenant",
                  label: "Owner and Tenant",
                },
              ]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.occupancy}
            />
          </div>
        </div>
        <div>
          <Input
            type="text"
            label="Style"
            name="style"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.style}
          />
        </div>
      </div>
      <h2
        className="formtitle"
        style={{ fontSize: "2.5rem", marginTop: "2.5rem" }}
      >
        PRIOR ENCUMBRANCES (EXISTING OR ANTICIPATED)
      </h2>
      <div
        className={`${styles.inputHeader} inputRow ${styles.fourthStepSecondSection}`}
      >
        <div></div>
        <div>
          <label>1st Mortgage</label>
        </div>
        <div>
          <label>2nd Mortgage</label>
        </div>
        <div>
          <label>3rd Mortgage</label>
        </div>
      </div>
      <div
        className={`${styles.inputHeader} inputRow ${styles.fourthStepSecondSection}`}
      >
        <div>
          <label>Mortgage Holder</label>
        </div>
        <div>
          <Input
            type="text"
            name="mortgageHolderFirst"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.mortgageHolderFirst}
          />
          {/* <CurrencyInputTag
            name="mortgageHolderFirst"
            onChange={(e) => {
              const value = e.target.value;
              const parsedValue = value.replace(/[^\d.]/gi, "");
              const currencyInt = parseFloat(parsedValue);
              const currencyValue = currencyInt.toLocaleString();
              formik.setFieldValue("mortgageHolderFirst", currencyValue);
            }}
            onBlur={formik.handleBlur}
          /> */}
        </div>
        <div>
          <Input
            type="text"
            name="mortgageHolderSecond"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.mortgageHolderSecond}
          />
          {/* <CurrencyInputTag
            name="mortgageHolderSecond"
            onChange={(e) => {
              const value = e.target.value;
              const parsedValue = value.replace(/[^\d.]/gi, "");
              const currencyInt = parseFloat(parsedValue);
              const currencyValue = currencyInt.toLocaleString();
              formik.setFieldValue("mortgageHolderSecond", currencyValue);
            }}
            onBlur={formik.handleBlur}
          /> */}
        </div>
        <div>
        <Input
            type="text"
            name="mortgageHolderThird"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.mortgageHolderThird}
          />
          {/* <CurrencyInputTag
            name="mortgageHolderThird"
            onChange={(e) => {
              const value = e.target.value;
              const parsedValue = value.replace(/[^\d.]/gi, "");
              const currencyInt = parseFloat(parsedValue);
              const currencyValue = currencyInt.toLocaleString();
              formik.setFieldValue("mortgageHolderThird", currencyValue);
            }}
            onBlur={formik.handleBlur}
          /> */}
        </div>
      </div>
      <div
        className={`${styles.inputHeader} inputRow ${styles.fourthStepSecondSection}`}
      >
        <div>
          <label>Mortgage Balance</label>
        </div>
        <div>
          <CurrencyInputTag
            name="mortgageBalanceFirst"
            onChange={(e) => {
              const value = e.target.value;
              const parsedValue = value.replace(/[^\d.]/gi, "");
              const currencyInt = parseFloat(parsedValue);
              const currencyValue = currencyInt.toLocaleString();
              formik.setFieldValue("mortgageBalanceFirst", currencyValue);
            }}
            onBlur={formik.handleBlur}
          />
        </div>
        <div>
          <CurrencyInputTag
            name="mortgageBalanceSecond"
            onChange={(e) => {
              const value = e.target.value;
              const parsedValue = value.replace(/[^\d.]/gi, "");
              const currencyInt = parseFloat(parsedValue);
              const currencyValue = currencyInt.toLocaleString();
              formik.setFieldValue("mortgageBalanceSecond", currencyValue);
            }}
            onBlur={formik.handleBlur}
          />
        </div>
        <div>
          <CurrencyInputTag
            name="mortgageBalanceThird"
            onChange={(e) => {
              const value = e.target.value;
              const parsedValue = value.replace(/[^\d.]/gi, "");
              const currencyInt = parseFloat(parsedValue);
              const currencyValue = currencyInt.toLocaleString();
              formik.setFieldValue("mortgageBalanceThird", currencyValue);
            }}
            onBlur={formik.handleBlur}
          />
        </div>
      </div>
      <div
        className={`${styles.inputHeader} inputRow ${styles.fourthStepSecondSection}`}
      >
        <div>
          <label>Monthly Payments</label>
        </div>
        <div>
          <CurrencyInputTag
            name="mortgagePaymentFirst"
            onChange={(e) => {
              const value = e.target.value;
              const parsedValue = value.replace(/[^\d.]/gi, "");
              const currencyInt = parseFloat(parsedValue);
              const currencyValue = currencyInt.toLocaleString();
              formik.setFieldValue("mortgagePaymentFirst", currencyValue);
            }}
            onBlur={formik.handleBlur}
          />
        </div>
        <div>
          <CurrencyInputTag
            name="mortgagePaymentSecond"
            onChange={(e) => {
              const value = e.target.value;
              const parsedValue = value.replace(/[^\d.]/gi, "");
              const currencyInt = parseFloat(parsedValue);
              const currencyValue = currencyInt.toLocaleString();
              formik.setFieldValue("mortgagePaymentSecond", currencyValue);
            }}
            onBlur={formik.handleBlur}
          />
        </div>
        <div>
          <CurrencyInputTag
            name="mortgagePaymentThird"
            onChange={(e) => {
              const value = e.target.value;
              const parsedValue = value.replace(/[^\d.]/gi, "");
              const currencyInt = parseFloat(parsedValue);
              const currencyValue = currencyInt.toLocaleString();
              formik.setFieldValue("mortgagePaymentThird", currencyValue);
            }}
            onBlur={formik.handleBlur}
          />
        </div>
      </div>
      <div
        className={`${styles.inputHeader} inputRow ${styles.fourthStepSecondSection}`}
      >
        <div>
          <label>Interest Rate (%)</label>
        </div>
        <div>
          <Input
            type="number"
            name="interestRateFirst"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.interestRateFirst}
          />
        </div>
        <div>
          <Input
            type="number"
            name="interestRateSecond"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.interestRateSecond}
          />
        </div>
        <div>
          <Input
            type="number"
            name="interestRateThird"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.interestRateThird}
          />
        </div>
      </div>
      <div
        className={`${styles.inputHeader} inputRow ${styles.fourthStepSecondSection}`}
      >
        <div>
          <label>Maturity Date</label>
        </div>
        <div>
          <Input
            type="text"
            name="maturityDateFirst"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.maturityDateFirst}
          />
        </div>
        <div>
          <Input
            type="text"
            name="maturityDateSecond"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.maturityDateSecond}
          />
        </div>
        <div>
          <Input
            type="text"
            name="maturityDateThird"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.maturityDateThird}
          />
        </div>
      </div>
      <div
        className={`${styles.inputHeader} inputRow ${styles.fourthStepSecondSection}`}
      >
        <div>
          <label>Arrears (If Any)</label>
        </div>
        <div>
          <CurrencyInputTag
            name="arrearsFirst"
            onChange={(e) => {
              const value = e.target.value;
              const parsedValue = value.replace(/[^\d.]/gi, "");
              const currencyInt = parseFloat(parsedValue);
              const currencyValue = currencyInt.toLocaleString();
              formik.setFieldValue("arrearsFirst", currencyValue);
            }}
            onBlur={formik.handleBlur}
          />
        </div>
        <div>
          <CurrencyInputTag
            name="arrearsSecond"
            onChange={(e) => {
              const value = e.target.value;
              const parsedValue = value.replace(/[^\d.]/gi, "");
              const currencyInt = parseFloat(parsedValue);
              const currencyValue = currencyInt.toLocaleString();
              formik.setFieldValue("arrearsSecond", currencyValue);
            }}
            onBlur={formik.handleBlur}
          />
        </div>
        <div>
          <CurrencyInputTag
            name="arrearsThird"
            onChange={(e) => {
              const value = e.target.value;
              const parsedValue = value.replace(/[^\d.]/gi, "");
              const currencyInt = parseFloat(parsedValue);
              const currencyValue = currencyInt.toLocaleString();
              formik.setFieldValue("arrearsThird", currencyValue);
            }}
            onBlur={formik.handleBlur}
          />
        </div>
      </div>
      <div className="inputRow">
        <div className={styles.acceptanceField}>
          <Input
            type="checkbox"
            label="I/We the undersigned, declare the information provided in the mortgage applicaiton is true and a complete representation. I/We authorize MortgagePro Ltd. or their designate to obtain a credit report. I/We authorize MortgagePro Ltd. to obtain further information to confirm the details that have been provided and the creditworthiness of the application. I/We expressly consent to the submission of my applicaiton and the disclosure of information to third parties, such as lenders and insurers, for the purpose of arranging and/or renewing mortgage(s). MortgagePro Ltd. may collect and use personal information from you and about you to meet all legal and regulatory requirements."
            name="acceptance"
            border={
              formik.touched.acceptance &&
              formik.errors.acceptance &&
              "2px solid var(--redColor)"
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.acceptance}
          />
          {formik.touched.acceptance && formik.errors.acceptance && (
            <p className="errorText">{formik.errors.acceptance}</p>
          )}
        </div>
      </div>
      <div className="navBtnContainer">
        <Button
          type="button"
          label="Back"
          maxWidth="15rem"
          onClick={props.back}
        />
        <Button
          type="submit"
          label="Submit"
          maxWidth="15rem"
          loading={props.loading}
        />
      </div>
    </form>
  );
};

export default StepFour;
