export const formatInputDate = (value) => {
  if (!value) return value;

  const dateInput = value.replace(/[^\d]/g, "");

  const dateInputLength = dateInput.length;

  if (dateInputLength <= 2) return dateInput;

  if (dateInputLength <= 4) {
    return `${dateInput.slice(0, 2)}/${dateInput.slice(2)}`;
  }

  return `${dateInput.slice(0, 2)}/${dateInput.slice(2, 4)}/${dateInput.slice(
    4,
    8
  )}`;
};

export const formatNumberWithDollar = (value) => {
  // convert value to string and remove non-digits
  let newValue = value.replace(/[^\d]/g, "");
  // format number as US currency
  newValue = (Number(newValue) / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
  return newValue;
};
